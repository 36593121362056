@import '../../../Styles/Mixin.scss';

@media (min-width: 600px) {
    .radio{
        &:hover{
            .circle{
                border: 6px solid #707070;
            }
        }
    }
}

.radio{
    cursor: pointer;
    max-width: 300px;
    margin: 10px 0;
    text-align: left;
    .circle{
        position: relative;
        box-sizing: border-box;
        vertical-align: middle;
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 10px;
        box-shadow: 2px 2px 2px #0000001A;
        border: 1px solid #707070;
        border-radius: 50%;
    }
    .text{
        display: inline-block;
        vertical-align: middle;
        line-height: 22px;
    }
    &.active{
        .circle{
            border: 6px solid #5DBD87;
        }
    }
}