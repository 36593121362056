@import '../../Styles/Mixin.scss';

#recuperar-senha{
    padding-top: 30px;
}

#login{
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    min-height: 100vh;

    background-image: url('Images/bg.jpg');
    background-size: 110%;
    background-position: -50px 0;
    form{
        box-sizing: border-box;
        width: 590px;
        padding: 50px 70px;
        margin: auto;

        text-align: center;
        background: #f9f9f9;
        box-shadow: 0px 25px 20px #00000029;
        border: 1px solid #707070;
        border-radius: 20px;

        animation-name: backInDown;
        animation-duration: 1s;
        .logo{
            img{
                width: 300px;
                margin-bottom: 20px;
            }
        }
        h2{
            font-size: 120%;
        }
        input[type=text],
        input[type=password]{
            text-align: center;
            background-color: #ffffff;
            border: 1px solid #DFDFDF;
        }
    }
}

@media (max-width: 600px) {
    #login{
        padding-top: 0;
    
        form{
            box-sizing: border-box;
            width: 100%;
            min-height: 100vh;
            padding: 70px 20px 20px 20px;
            margin: auto;
    
            box-shadow: none;
            border: none;
            border-radius: 0;

            a{
                display: block;
                margin-bottom: 40px;
            }
        }
    }
}