@import '../../../Styles/Mixin.scss';

@media (min-width: 600px) {
    .checkbox{
        &:hover{
            .square{
                &:before{
                    opacity: 1;
                }
            }
        }
    }
}

.checkbox{
    cursor: pointer;
    max-width: 300px;
    margin: 10px 0;
    text-align: left;
    .square{
        box-sizing: border-box;
        vertical-align: middle;
        display: inline-block;
        width: 22px;
        height: 22px;
        margin-right: 10px;
        box-shadow: 2px 2px 2px #0000001A;
        border: 1px solid #707070;
        border-radius: 3px;
        &:before{
            display: block;
            content: "✔";
            width: 20px;
            text-align: center;
            transition: .2s;
            opacity: 0;
        }
    }
    .text{
        display: inline-block;
        vertical-align: middle;
        line-height: 22px;
    }
    &.active{
        .square{
            border: 1px solid #5DBD87;
            &:before{
                opacity: 1;
                color: #5DBD87;
                animation-name: swing;
                animation-duration: .4s;
            }
        }
    }
}