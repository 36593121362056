@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap');
@import './Mixin.scss';

*, input, select{
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    outline: none;
    border: none;
    list-style: none;
    text-decoration: none;
    color: $color_default;
    padding: 0;
    margin: 0;    
    font-size: 1rem;
    line-height: 1.4rem;
    -webkit-appearance: none;
}
a{
    text-decoration: none !important;
}
a.voltar{

}
select{
    background: url("../Images/ico-arrow-down.svg") no-repeat 97% center;
    background-size: auto 30%;
}
#meat .sidebar.active nav::-webkit-scrollbar {
    width: 12px;               /* width of the entire scrollbar */
}

#meat .sidebar.active nav::-webkit-scrollbar-track {
    background:  #ff2e6033;        /* color of the tracking area */
}

#meat .sidebar.active nav::-webkit-scrollbar-thumb {
    background-color: #4a4a4a33;    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 1px solid #ff2e6033;    /* creates padding around scroll thumb */
}

/**
 * Main wrapper
 */
.select-search {
    width: 300px;
    position: relative;
    font-family: 'Nunito Sans', sans-serif;
    box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
    box-sizing: inherit;
}

/**
 * Value wrapper
 */
.select-search__value {
    position: relative;
    z-index: 1;
}

.select-search__value::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: calc(50% - 9px);
    right: 19px;
    width: 11px;
    height: 11px;
}

/**
 * Input
 */
.select-search__input {
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 40px 0 16px;
    background: #fff;
    border: 1px solid transparent;
    box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    outline: none;
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    text-align: left;
    text-overflow: ellipsis;
    line-height: 36px;
    -webkit-appearance: none;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
    -webkit-appearance:none;
}

.select-search__input:not([readonly]):focus {
    cursor: initial;
}

/**
 * Options wrapper
 */
.select-search__select {
    background: #fff;
    box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
}

/**
 * Options
 */
.select-search__options {
    list-style: none;
}

/**
 * Option row
 */
.select-search__row:not(:first-child) {
    border-top: 1px solid #eee;
}

/**
 * Option
 */
.select-search__option,
.select-search__not-found {
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 16px;
    background: #fff;
    border: none;
    outline: none;
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
}

.select-search--multiple .select-search__option {
    height: 48px;
}

.select-search__option.is-selected {
    background: #2FCC8B;
    color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
    background: rgba(47, 204, 139, 0.1);
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
    background: #2eb378;
    color: #fff;
}

/**
 * Group
 */
.select-search__group-header {
    font-size: 10px;
    text-transform: uppercase;
    background: #eee;
    padding: 8px 16px;
}

/**
 * States
 */
.select-search.is-disabled {
    opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
    background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
    cursor: pointer;
}

/**
 * Modifiers
 */
.select-search--multiple {
    border-radius: 3px;
    overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
    transform: rotate(45deg);
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    pointer-events: none;
}

.select-search--multiple .select-search__input {
    cursor: initial;
}

.select-search--multiple .select-search__input {
    border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
    cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
    border-color: #2FCC8B;
}


.select-search:not(.select-search--multiple) .select-search__select {
    position: absolute;
    z-index: 2;
    top: 44px;
    right: 0;
    left: 0;
    border-radius: 3px;
    overflow: auto;
    max-height: 360px;
}

.select-search--multiple .select-search__select {
    position: relative;
    overflow: auto;
    max-height: 260px;
    border-top: 1px solid #eee;
    border-radius: 0 0 3px 3px;
}

.select-search__not-found {
    height: auto;
    padding: 16px;
    text-align: center;
    color: #888;
}


.content{
    width: 98%;
    max-width: 98%;
    margin: auto;
    padding-left: 307px;
    box-sizing: border-box;
}
.css-2b097c-container{
    position: relative;
    z-index: 9999;
}

div.card{
    h2{
        margin-bottom: 0;
    }
}
.display-none{
    display: none;
}
.card-body p{
    font-weight: 300;
}
section.content-page{
    padding-top: 80px;
}
ul.paginacao{
    margin-bottom: 0;
}
li.paginacao{
    display: inline-block;
    margin-bottom: 0;
}

.content-box{
    box-sizing: border-box;
    padding: 40px;
    margin-bottom: 40px;
    background: #FFFFFF;
    box-shadow: 0px 3px 5px #0000000D;
    border-radius: 5px;
    p{
        text-align: justify;
    }
}

h1,h2,h3,h4,h5,h6{
    font-size: 120%;
    line-height: 140%;
    font-weight: 600;
}

.title{
    font-size: 200%;
}

.text-center{
    text-align: center;
}

p{
    margin: 10px 0;
}

b{
    font-weight: 600;
}

hr{
    margin: 20px 0;
    width: 100%;
    height: 1px;
    background-color: #E7E7E7;
}

button{
    background-color: transparent;
    cursor: pointer;
}

a{
    cursor: pointer;
    font-weight: 600;
    &:hover{
        opacity: .9;
        text-decoration: underline;
    }
}
table{
    margin-bottom: 0 !important;
    td{
        vertical-align: middle;
    }
}
.pagination .btn{
    margin-top: 0;
    margin-bottom: 0;
    border-radius: 0;
    margin-right: 10px;
}
.btn.criar{
    border-radius: 0;
}
.btn.editar{
    width: 100%;
}
.btn{
    cursor: pointer;
    box-sizing: border-box;
    display: block;
    position: relative;
    z-index: 1;

    padding: 8px;
    margin: 0;
    font-weight: 600;
    text-align: center;
    &:hover{
        opacity: .9;
    }
    &.btn-inline{
        display: inline-block;
        width: 300px;
    }

    &.btn-link{
        text-align: center;
        background-color: transparent!important;
        background-image: none!important;
        color: $color_primary;
        box-shadow: none!important;
    }
    &.btn-margin-none{
        margin: 0;
    }

}

.btn-pink{
    cursor: pointer;
    box-sizing: border-box;
    display: block;
    position: relative;
    z-index: 1;
    padding: 12px;
    margin: 20px 0;
    border-radius: 25px;
    background-color: $color_primary;
    border-color: $color_primary;
    color: #fff;
    font-weight: 600;
    text-align: center;
    &:hover{
        opacity: .9;
    }
    &.btn-inline{
        display: inline-block;
        width: 300px;
    }
    &.btn-outline{
        background-color: transparent;
        border: 1px solid $color_primary;
        color: $color_primary;
        &:hover{
            background-color: $color_primary;
            color: #FFF;
        }
    }
    &.btn-link{
        text-align: center;
        background-color: transparent!important;
        background-image: none!important;
        color: $color_primary;
        box-shadow: none!important;
    }
    &.btn-margin-none{
        margin: 0;
    }
}


.btn-success {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
    border-color: #28a745 !important;
    &:hover{
        background-color: #28a745 !important;
        color: #FFF !important;
        border-color: #28a745 !important;
    }
}

.color-primary{
    color: $color_primary;
}

label{
    display: block;
    width: 100%;
    margin: 10px 0;
}
input[type=text],
input[type=tel],
input[type=password],
textarea,
select{
    box-sizing: border-box;
    padding: 12px 20px;
    width: 100%;
    background-color: #FFF;
    &:hover,
    &:focus{
        box-shadow: 0px 4px 4px #0000000A;
    }
    &.calendar{
        background-image: url("../Images/ico-calendar.svg");
        background-size: auto 45%;
        background-position: 15px center;
        background-repeat: no-repeat;
        padding-left: 45px;
    }
    &.clock{
        background-image: url("../Images/ico-clock.svg");
        background-size: auto 45%;
        background-position: 15px center;
        background-repeat: no-repeat;
        padding-left: 45px;
    }
}
textarea{
    resize: none;
    min-height: 100px;
}
select{
    padding: 12px 15px;
}

@media (min-width: 600px) {
    .btn-back{
        background: url("../Images/ico-arrow-right.svg") no-repeat center;
        transform: rotate(180deg);
        float: left;
        width: 40px;
        height: 30px;
        margin-top: 3px;
    }
    .desktop-hide{
        display: none;
    }
}

@media (max-width: 600px) {
    *, input{
        font-size: 1rem;
        line-height: 1.4rem;
    }

    .mobile-hide{
        display: none;
    }

    .content{
        max-width: 90%;
        margin: auto;
    }

    .title{
        font-size: 120%;
        text-align: center;
    }

    hr{
        margin: 40px -5%;
        width: 110%;
        height: 1px;
        background-color: #DDD;
    }

    .btn{
        width: 100%!important;
        padding: 15px;
    }

    .btn-back{
        background: url("../Images/ico-arrow-right.svg") no-repeat center;
        transform: rotate(180deg);
        width: 20px;
        height: 20px;
    }

    label{
    }
    input[type=text], 
    input[type=tel], 
    input[type=password], 
    textarea, 
    select{
        padding: 17px;
    }
}