@import '../../../Styles/Mixin.scss';

@media (min-width: 600px) {
    .switch{
    }
}

.switch{
    cursor: pointer;
    .text{
        display: inline-block;
        vertical-align: middle;
        margin: 0 5px;
        color: $color_primary;
    }
    .button{
        position: relative;
        display: inline-block;
        vertical-align: middle;
        width: 35px;
        height: 20px;
        border-radius: 20px;
        background-color: #BEBEBE;
        .ball{
            position: absolute;
            top: 2px;
            left: 2px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #fff;
            transition: .2s;
        }
    }
    &.active{
        .text{
            color: $color_default;
        }
        .button{
            background-color: $color_featured;
            .ball{
                left: 18px;
            }
        }
    }

}