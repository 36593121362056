.modal{
    .modalOverlay{
        cursor: default;
        z-index: 8;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        background-color: rgba(0,0,0,0.8);
        overflow: auto;
        visibility: hidden;
        opacity: 0;
        transition: .4s;
    }
    .modalContent{
        box-sizing: border-box;
        z-index: 999;
        position: fixed;
        top: -140vh;
        left: calc(50% - 250px);
        width: 500px;
        max-height: 90vh;
        padding: 30px 30px 0px 30px;
        background-color: #F2F2F4;
        border-radius: 25px;
        box-shadow: 0px 7px 6px #00000029;
        transition-delay: .8s;
        transition: .8s;
        overflow: auto;
    }
    &.active{
        .modalOverlay{
            visibility: visible;
            opacity: 1;
        }
        .modalContent{
            top: 50px;
            animation-name: backInDown;
            animation-duration: 1s;
        }
    }
}

@media (min-width: 600px){
    .modal{
        .modalContent{
            .header-mobile{
                padding-top: 10px;
                .btn-back{
                    display: none;
                }
            }
        }
    }
}

@media (max-width: 600px){
    .modal{
        .modalContent{
            width: calc(100% - 40px);
            position: fixed;
            left: 3%;
            min-width: 94%;
            max-height: calc(100vh - 4%);
        }
        &.active{
            .modalContent{
                top: 2%;
            }
        }
    }
}
