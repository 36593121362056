@import '../../Styles/Mixin.scss';


button i{
    color: #fff
}
@media (min-width: 600px) {
    #meat{
        background-color: #F2F2F4;
        width: 100%;
        min-height: 100vh;

        .sidebar{
            transition: .2s;
            opacity: 0;
            nav{
                overflow-x: hidden;
                overflow-y: auto;
                position: fixed;
                left: -300px;
                width: 300px;
                height: 100vh;
                z-index: 9;
                background-color: #f7f7f7;
                box-shadow: 0px 3px 6px #00000029;
                transition: .4s;
                p{
                    padding: 2px 30px;
                    font-weight: bold;
                    font-size: 13px;
                    color: $color_gray_light;
                }
                .btn-sidebar-close{
                    display: none;
                }
                .user{
                    align-items: center;
                    padding: 15px;
                    padding-bottom: 0;
                    text-align: center;
                    .logo{
                        display: inline-block;
                        width: 100%;
                        height: 100px;
                        img{
                            display: inline-block;
                            width: 100%;
                        }
                    }
                    span.welcome{
                        font-size: 16px;
                        color:$color_default;
                        display: inline-block;
                        width: 100%;
                    }

                }
                ul{
                    li{
                        a, button{
                            box-sizing: border-box;
                            display: block;
                            width: 100%;
                            padding: 15px 30px;
                            font-size: 16px;
                            color: $color_gray;
                            padding-left: 60px;

                            background-color: transparent;
                            font-weight: 600;
                            text-align: left;
                            background-position: 27px center;
                            background-repeat: no-repeat;

                            &.um{
                                padding-left: 30px;
                                i{
                                    font-size: 17px;
                                    margin-right: 5px;
                                }
                            }
                            &.dois{
                                padding-left: 30px;
                            }
                            &.tres{
                                background-image: url("../../Images/menu/icon-cookers.svg");
                            }
                            &.quatro{
                                background-image: url("../../Images/menu/icon-produtos.svg");
                            }
                            &.cinco{
                                background-image: url("../../Images/menu/icon-loop.svg");
                            }
                            &.seis{
                                background-image: url("../../Images/menu/icon-pedidos.svg");
                            }
                            &.sete{
                                background-image: url("../../Images/menu/icon-denuncia.svg");
                            }
                            &.oito{
                                background-image: url("../../Images/menu/icon-ajuda.svg");
                            }
                            &.nove{
                                background-image: url("../../Images/menu/icon-cookers.svg");
                            }
                            &.dez{
                                background-image: url("../../Images/menu/icon-menu.svg");
                            }
                            &.onze{
                                background-image: url("../../Images/menu/icon-banners.svg");
                            }
                            &.doze{
                                background-image: url("../../Images/menu/icon-destaques.svg");
                            }
                            &.treze{
                                background-image: url("../../Images/menu/icon-ajuda.svg");
                            }
                            &.quatorze{
                                background-image: url("../../Images/menu/icon-ajuda.svg");
                            }
                            &:hover, 
                            &.active{
                                color: $color-primary;
                                text-decoration: none;
                                background-color: #f5f5f5;
                            }

                            i{
                                font-size: 20px;
                                vertical-align: middle;
                                color: $color_gray;
                                margin-right: 10px;
                            }

                        }
                    }
                }
            }
            &.active{
                position: fixed;
                z-index: 9;
                height: 100vh;
                background-color: rgba(0,0,0,.8);
                opacity: 1;
                nav{
                    left: 0;
                }
            }
        }

        header {
            width: calc(100% - 300px);
            right: 0;
            padding: 0px 0;
            box-sizing: border-box;
            position: fixed;
            z-index: 8;
            background-color: #fff;
            box-shadow: 0px 3px 5px #0000000D;

            &.blur{
                filter: blur(5px);
            }
            .content{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                > div{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    position: relative;
                    z-index: 99999;
                }

                .title{
                    display: none;
                }

                .btn-sidebar{
                    width: 35px;
                    height: 26px;
                    position: relative;
                    border-bottom: 5px solid $color-primary; 
                    background-color: transparent;
                    
                    &:before,
                    &:after{
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 5px;
                        background-color: $color-primary;
                    }
                    &:after{
                        top: 10px;
                    }
                }
        
                .form-search{
                    width: 500px;
                    margin-left: 50px;
                    input{
                        background-color: #F2F2F4;
                        background-image: url('../../Images/ico-search.svg');
                        background-repeat: no-repeat;
                        background-position: 10px center;
                        padding-left: 45px;
                    }
                }
        
                .btn-featured{
                    margin: 0 50px 0 0;
                    width: 290px;
                    text-align: center;
                }
        
                .user{
                    white-space: nowrap;
                    display: flex;
                    align-items: center;
                    .image{
                        display: block;
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                        background-image: url('../../Images/bg-user.png');
                        background-repeat: no-repeat;
                        background-size: cover;
                        margin-right: 20px;
                    }
                }
            }
        }

        section.container{
            padding-top: 180px;
            &.blur{
                filter: blur(5px);
            }
        }

    }
}

@media (max-width: 600px) {
    #meat{
        background-color: #F2F2F4;
        width: 100%;
        min-height: 100vh;
    
        .sidebar{
            nav{
                position: absolute;
                left: -100%;
                width: 100%;
                height: 100vh;
                z-index: 9;
                transition: .2s;
                .btn-sidebar-close{
                    position: absolute;
                    top: 30px;
                    right: 30px;
                    color: $color-primary;
                    background-color: transparent;
                    font-size: 200%;
                    font-weight: 800;
                }
                .user{
                    white-space: nowrap;
                    display: flex;
                    align-items: center;
                    padding: 20px;
                    background-color: #fff;
                    border-bottom: 1px solid #E7E7E7;
                    .image{
                        display: block;
                        width: 100px;
                        height: 100px;
                        border-radius: 50%;
                        background-image: url('../../Images/bg-user@2x.png');
                        background-repeat: no-repeat;
                        background-size: cover;
                        margin-right: 20px;
                    }
                    .welcome{
                        .name{
                            display: block;
                            font-weight: 600;
                        }
                    }
                }
                ul{
                    li{
                        a, button{
                            box-sizing: border-box;
                            display: block;
                            width: 100%;
                            padding: 25px 30px;
                            border-bottom: 1px solid #E7E7E7;
                            background-color: #f2f2f4;
                            font-weight: 600;
                            text-align: left;
                            &:hover, 
                            &.active{
                                color: $color-primary;
                                text-decoration: none;
                            }
                            &:after{
                                content: "";
                                float: right;
                                width: 10px;
                                height: 20px;
                                background-image: url('../../Images/ico-arrow-right.svg');
                                background-repeat: no-repeat;
                                background-position: center right;
                            }
                        }
                    }
                }
            }
            &.active{
                nav{
                    left: 0;
                }
            }
        }
    
        header {
            z-index: 2;
            padding: 20px 0;
            
            .content{
    
                .btn-sidebar{
                    float: left;
                    width: 35px;
                    height: 26px;
                    position: relative;
                    border-bottom: 5px solid $color-primary; 
                    background-color: transparent;
                    z-index: 1;
                    
                    &:before,
                    &:after{
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 5px;
                        background-color: $color-primary;
                    }
                    &:after{
                        top: 10px;
                    }
                }

                .title{
                    text-align: center;
                }
        
                .form-search{
                    width: 100%;
                    display: block;
                    input{
                        margin-top: 20px;
                        padding-left: 45px;
                        background-color: #fff;
                        background-image: url('../../Images/ico-search.svg');
                        background-repeat: no-repeat;
                        background-position: 10px center;
                        box-shadow: 0px 3px 5px #0000000D;
                    }
                }
        
                .btn-featured{
                    display: none;
                }
        
                .user{
                    display: none;
                }
            }
        }
    
        section.container{
        }
    
    }
}