@import '../../../Styles/Mixin.scss';

.uploadPhoto{
    > input[type=file]{
        display: none;
    }
    > button{
        position: relative;
        box-sizing: border-box;
        padding: 12px 20px;
        width: 100%;
        border-radius: 25px;
        text-align: left;
        background-color: #FFF;
        &:hover{
            box-shadow: 0px 4px 4px #0000000A;
            &:after{
                transform: rotate3d(0, 1, 0, 360deg);
            }
        }
        &:after{
            content: "";
            position: absolute;
            top: 7px;
            right: 7px;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background: url("../../../Images/ico-camera.svg") #BEBEBE no-repeat center;
            background-size: 60%;
            transition: .4s;
        }
    }
    &.active{
        > button{
            color: #5DBD87;
            &:after{
                background: url("../../../Images/ico-check.svg") #5DBD87 no-repeat center;
                background-size: 45%;
            }
        }
    }

}