@import '../../Styles/Mixin.scss';

.messageContainer{
    z-index: 99999999!important;
    &.messageOverlay{
        background: rgba(0,0,0,.75)!important;
    }
    .messagePopup{
        background-color: #F2F2F4;
        border-radius: 20px;
        border: 1px solid #ddd;
        box-shadow: 0px 7px 6px #00000029;
        &.messageLogo{
            &:before{
                content: "";
                width: 200px;
                height: 100px;
                margin: 30px auto;
                background: url('../../Images/logo-alt.png') no-repeat center;
                background-size: 100% auto;
            }
        }
        &.messageError{
            .swal2-title{
                color: #ff2e60;
            }
            .swal2-timer-progress-bar{
                background: #ff2e60;
            }
        }
        .messageTitle{
            font-size: 120%;
            line-height: 140%;
            font-weight: 600;
        }
        .btn{
            border-radius: 25px;
            background-color: #ff2e60;
        }
        .swal2-icon{
            transform: scale(.8);
        }
    }
}

@media (max-width: 600px) {
    .messageContainer{
        width: 96%;
    }
}